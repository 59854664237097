<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>全员营销</el-breadcrumb-item>
            <el-breadcrumb-item>分销设置</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="cont-form-box">
         <!-- 表单 -->
         <el-form class="form-box">
            <el-form-item label="分销">
               <el-radio v-model="content.distribution" label="NO">关闭</el-radio>
               <el-radio v-model="content.distribution" label="YES">开启</el-radio>
               <p>分销开关，关闭后不发放分销佣金，隐藏分销相关功能及界面</p>
            </el-form-item>
            <el-form-item label="分销海报" required>
               用于用户分享时，生成海报使用。除营销产品设置部分，其它未设置的地方，分销都会使用当前海报
               <el-upload
                     class="avatar-uploader"
                     ref="upload"
                     :show-file-list="false"
                     action="#"
                     :auto-upload="false"
                     :on-change="handleUpload">
                  <img v-if="posterUrl" :src="posterUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
               </el-upload>
            </el-form-item>
            <el-form-item label="分销文案">
               <el-input type="textarea" size="medium" v-model="content.paperwork" placeholder="填写分享时显示的营销文案" clearable></el-input>
            </el-form-item>
            <el-form-item>
               <el-button class="m-right-30" type="primary" plain @click="handleReset()" v-text="$t('msg.reset')" size="medium">重置</el-button>
               <el-button class="bg-gradient"  type="primary" @click="handleSave" v-text="$t('msg.save')" size="medium">保存</el-button>
            </el-form-item>
         </el-form>
      </el-row>
   </section>
</template>

<script>
import { mapState } from 'vuex'
import { urlObj } from '@/api/interface'
import { marketing } from '@/api/interface/smtech'
import { beforeUpload } from "@/common/js/common";
export default {
   data(){
      return{
         posterUrl: '',             // 海报路径
         content: {
            distribution: 'YES',       // 分销状态
            paperwork: '',             // 分销文案
         },
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   mounted() {
      this.getDistributionSet()
   },
   methods: {
      // 获取分销设置信息
      getDistributionSet() {
         const url = marketing.getContentSetting
         const param = { id: this.hotelInfo.id }
         this.$axios.get(url, param).then(res => {
            if (res.success) {
               let content = JSON.parse(res.records.content)
               this.posterUrl = window.getResourcesHost() + content.posterShare
               delete content.posterShare
               this.content = content
            }
         })
      },
      // 获取上传的文件资源
      handleUpload(file){
         console.log(file)
         let err = beforeUpload(file)
         if (err) return this.$message.error(err)
         this.posterUrl = URL.createObjectURL(file.raw)
         this.file = file.raw
      },
      // 保存设置
      handleSave() {
         const url = marketing.contentSetting
         let param = new FormData()
         if (this.file){
            param.append('file', this.file)                // 文件
            param.append('module', this.hotelInfo.hotelCode)         // 所在模块
            param.append('menu', 'marketing')          // 所属菜单
            param.append('func', 'setting')             // 图片功能
            param.append('category', 'image')         // 图片分类
            param.append('isThumb', true)            // 是否开启缩略图
         }
         param.append('content', JSON.stringify(this.content))
         param.append('id', this.hotelInfo.id)
         this.$axios.post(url, param, 'file').then((res) => {
            if (res.success) {
               this.$message({
                  showClose: true,
                  message: '设置成功！',
                  type: 'success'
               })
            }
         })
      },
      // 返回上页
      goBack() {
         this.$router.go(-1)
      }
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         if (newVal.id !== oldVal.id && oldVal) {
            this.getDistributionSet()
         }
      },
   }
}
</script>

<style scoped lang="scss"></style>
