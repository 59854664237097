/* 仪表盘 */
export const dashboard = {
   // 统计会员今日增量和总量
   statisticsMemberRecords: '/hadoop/getStatisticsMemberRecords',
   // 酒店客房复购率
   rePurchaseRate: '/hadoop/getStatisticsRePurchaseRate',
   // 统计周活跃老客户
   weekActiveUser: '/hadoop/getStatisticsWeekActiveUser',
   // 统计周沉睡客户唤醒数
   weekSleepUser: '/hadoop/getStatisticsWeekSleepUser',
}

/* 运营 */
export const operate = {
   // 检查活动名称重复
   checkNameIsExist: '/event/eventMessage/checkNameIsExist',
   // 创建活动
   createActivity: '/event/eventMessage/create',
   // 修改活动信息
   editActivity:'/event/eventMessage/update',
   // 获取运营活动列表
   operateActivity: '/event/eventMessage/getPage',
   // 统计活动总数
   statisticsActivity: '/event/eventMessage/statisticalMagnitude',
   // 删除小程序弹窗活动
   delAppletsPopup: '/event/eventMessage/delete',
   // 抖音上传视频
   uploadVideo: '/douyin/platform/uploadVideo',
   // 获取抖音视频列表
   tiktokVideo: '/event/douyin/platform/getVideoList',
   // 创建抖音活动
   createTiktok: '/event/eventMessage/createEvent',
   // 获取抖音授权码
   tiktokCode: '/oauth2/douyin/platform/getCode',
   //获取抖音token
   getAccessToken:'/oauth2/douyin/platform/getAccessToken',
   // 获取秒杀预售券列表
   seckillCouponPage: '/goods/seckillProduct/getTicketPage',
   // 新增秒杀商品
   addSeckillProduct: '/goods/seckillProduct/save',
   // 修改秒杀商品
   editSeckillProduct: '/goods/seckillProduct/update',
   // 删除秒杀商品
   delSeckillProduct: '/goods/seckillProduct/delete',
   // 获取秒杀时间段列表
   seckillTimePage: '/goods/seckillTime/getPage',
   // 获取秒杀时间段列表
   seckillTime: '/goods/seckillTime/getList',
   // 新增秒杀时段
   addSeckillTime: '/goods/seckillTime/save',
   // 编辑秒杀时段
   editSeckillTime: '/goods/seckillTime/update',
   // 删除秒杀时段
   delSeckillTime: '/goods/seckillTime/delete',
}

/* 全员营销 */
export const marketing = {
   /* 全员营销 */
   // 获取营销会员列表
   marketingMembers: '/event/marketingUser/getPage',
   // 获取推广会员列表
   promotionMembers: '/event/marketingUser/getPromotionDetailPage',
   // 修改上级推荐人
   updateParentId: '/event/marketingUser/updateParentId',
   // 查询营销收入明细
   marketingUserIncome: '/event/marketingUserIncome/getPage',
   // 获取客户关系列表
   relationship: '/event/marketingUser/getCRMList',
   // 分销设置
   contentSetting: '/hotel/contentSetting',
   // 获取分销设置信息
   getContentSetting: '/hotel/getContentSetting',
   //添加营销商品
   Modifymailtemplate:'/event/templateEmail/update',
   //获取营销商品列表 getList
   getmarketingproductslist:'/event/marketing/getPage',
   //获取营销商品列表
   getmarketingproductslists:'/event/marketing/getList',
   //获取营销分组列表
   getmarketinggroup:'/event/marketinggroup/getList',
   //删除营销商品
   deletelmarketproduct:'/event/marketing/delete/',
   //推广效果
   getPromotionOrderPage:"/event/marketingUserIncome/getPromotionOrderPage",
   //业绩统计
   getStatisticslist:'/event/marketingUser/getPerformanceStatistics',
   //商品推广效果
   getEffectOfCommodityPromotion:'/event/marketing/getEffectOfCommodityPromotion',
   //导出业绩统计接口
   exportStatisticsExcel:'/event/marketingUser/exportPerformanceStatisticsExcel',
   //导出推广订单
   exportPromotionOrder:'/event/marketingUserIncome/exportPromotionOrderPageExcel',
   //导出商品推广效果
   exportCommodityExcel:'/event/marketing/exportEffectOfCommodityPromotionExcel',
   //添加营销商品
   addmarketingproduct:'/event/marketing/add',
   //修改营销商品
   editmarketingproduct:'/event/marketing/edit',
   // 修改分销会员冻结资格
   updateFrozenStatus: '/event/marketingUser/updateStatus',
   //营销分组列表
   marketingGroups:'/event/marketinggroup/getMarketingGroupList',
   //添加营销分组
   saveMarketingGroup:'/event/marketinggroup/saveMarketingGroup',
   //获取营销商品详情
   getmarketingproductsdetails: '/event/marketing/get/',
   //调整分组等级
   moveMarketingGroup:'/event/marketinggroup/moveMarketingGroup',
   //分组删除
   deleteMarketingGroup: '/event/marketinggroup/deleteMarketingGroup/',
}
